import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, booleanAttribute, input, output } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { IconSize } from '../icon/icon-size.enum';
import { IconType } from '../icon/icon-type.enum';
import { IconsComponent } from '../icon/icon.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { SpinnerColorTypes } from '../spinner/spinner.type';
import { ChipType } from './chip-type.enum';

@Component({
  selector: 'myt-chip',
  templateUrl: './chip.component.html',
  standalone: true,
  imports: [NgClass, IconsComponent, MatChipsModule, SpinnerComponent, NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
  @Input({ required: true }) text = '';
  @Input() icon: string | undefined;
  @Input() avatar: string;
  readonly loading = input(false, { transform: (v: boolean | undefined) => Boolean(v) });
  @Input() spinnerColor: SpinnerColorTypes;
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input({ transform: booleanAttribute }) removable = false;
  @Input({ transform: booleanAttribute }) interactive = false;
  @Input() iconSize: IconSize | undefined = IconSize.ExtraSmall;
  readonly iconAlignment = input<'right' | 'left'>('left');
  readonly customChipClasses = input('');

  @Input() set type(value: ChipType) {
    this.chipTypeClass = `myt-chip--${value}`;
    this.iconType = value as unknown as IconType;
  }

  readonly chipClick = output<void>();
  readonly chipRemove = output<void>();

  readonly avatarPath = '/assets/images/';
  chipTypeClass = 'myt-chip--neutral';
  iconType: IconType = IconType.Neutral;

  onRemove(event: Event) {
    event.stopPropagation();
    this.chipRemove.emit();
  }
}
