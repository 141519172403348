<mat-chip
  class="myt-chip {{ chipTypeClass }} {{ customChipClasses() }}"
  [disabled]="disabled"
  (click)="chipClick.emit()"
  [ngClass]="{ 'myt-chip--interactive': interactive }"
  [disableRipple]="!interactive"
>
  @if (avatar) {
    <img class="myt-chip__avatar" matChipAvatar [src]="avatarPath + avatar" alt="avatar" />
  }
  @if (icon && iconAlignment() === 'left') {
    <ng-container *ngTemplateOutlet="iconTmp" />
  }
  @if (loading()) {
    <myt-spinner class="mr-2" [strokeWidth]="2" [diameter]="20" [color]="spinnerColor" />
  }
  <span class="myt-chip__text">{{ text }}</span>

  @if (icon && iconAlignment() === 'right') {
    <ng-container *ngTemplateOutlet="iconTmp" />
  }

  @if (removable) {
    <button class="myt-chip__remove-button" matChipRemove aria-label="remove chip" (click)="onRemove($event)">
      <myt-icon name="close" [size]="iconSize" />
    </button>
  }
</mat-chip>

<ng-template #iconTmp>
  <myt-icon class="myt-chip__icon-{{ iconAlignment() }}" [name]="icon || ''" [size]="iconSize" [type]="iconType" />
</ng-template>
